.referral-tree-container {
  background-color: #ededff;
  color: #000000;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
}

.referral-video-container {
  background-color: #ededff;
  color: #000000;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 8px;
}

.referral-video {
  color: #000000;
  text-align: center;
  max-width: 100%;
  max-height: 400px;
}

.referral-node {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.node-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.node-details {
  background: linear-gradient(45deg, #0d154b, #415caf);
  color: #ffffff;
  border: 2px solid #ffa500;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  width: 150px;
}

.node-details .address {
  color: #ffa500;
  font-weight: bold;
  margin-bottom: 12px;
}

.node-details div {
  margin: 0;
}

.address {
  color: #ffa500;
  font-weight: bold;
}

.children {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  max-width: 800px;
  font-size: 14px;
}

.back-button {
  position: absolute;
  top: 110px;
  left: 30px;
  padding: 10px 20px;
  background-color: #c0025e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
}

.back-button:hover {
  background-color: #c0025e;
}

.level-0 > .children {
  position: relative;
  margin-top: 30px;
}

.level-0 > .children::before {
  content: '';
  position: absolute;
  top: -30px;
  left: 50%;
  width: 2px;
  height: 13px;
  background-color: #ffa500;
}

.level-1, .level-2, .level-3, .level-4, .level-5, .level-6, .level-7, .level-8, .level-9, .level-10, .level-11, .level-12, .level-13, .level-14, .level-15, .level-16, .level-17, .level-18, .level-19, .level-20 {
  margin: 0 30px;
  position: relative;
}

.level-1::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  width: 2px;
  height: 20px;
  background-color: #ffa500;
}

.level-1:not(:only-child)::after {
  content: '';
  position: absolute;
  top: -20px;
  width: 100%;
  height: 2px;
  background-color: #ffa500;
}

.level-1:first-child:not(:only-child)::after {
  left: 50%;
}

.level-1:last-child:not(:only-child)::after {
  right: 50%;
}

.children:only-child {
  justify-content: center;
}

.level-1:only-child {
  margin: 0;
}

.level-1:only-child::before {
  left: 50%;
}

.level-1:only-child::after {
  display: none;
}

.referral-video-container h2 {
  color: #000000;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}

@media (max-width: 768px) {
  .referral-tree-container {
    margin-top: 22%;
    margin-left: 20px;
  }

  .back-button {
    top: 100px;
  }
}

@media (max-width: 1031px) {
  .referral-tree-container {
    margin-top: 10%;
  }
}

@media (max-width: 910px) {
  .referral-tree-container {
    margin-top: 12%;
  }
}

@media (max-width: 790px) {
  .referral-tree-container {
    margin-top: 14%;
  }
}

@media (max-width: 670px) {
  .referral-tree-container {
    margin-top: 16%;
  }
}

@media (max-width: 570px) {
  .referral-tree-container {
    margin-top: 18%;
  }
}

@media (max-width: 470px) {
  .referral-tree-container {
    margin-top: 20%;
  }
}

@media (max-width: 370px) {
  .referral-tree-container {
    margin-top: 22%;
  }
}