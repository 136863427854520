/* styles/Header.css */
.header {
  background-color: #ededff;
  color: black;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;  
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-right {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 1rem;
  margin-left: 40px;
}

.header-right {
  display: flex;
  align-items: center;
}

.connect-btn,
.logout-btn {
  background-color: #c0025e;
  color: #fff;
  padding: 8px 16px;
  transition: transform .2s;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.connect-btn:hover,
.logout-btn:hover {
  background-color: #ff007b;
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05); 
}