.sidebar-container {
  position: fixed;
  top: 84.3px;
  left: 0;
  height: 100%;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.sidebar-toggle {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 1001;
  background: transparent;
  color: #311163;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
}

.sidebar {
  background-color: #ededff;
  color: #000000;
  height: 100%;
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}

.sidebar-container.closed {
  transform: translateX(-100%);
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.sidebar-item.active {
  background: linear-gradient(45deg, #cccccc, #89a6ff);
  border-radius: 4px;
}

.sidebar-item:hover {
  background: linear-gradient(45deg, #cccccc, #89a6ff);
  border-radius: 4px;
}

.sidebar-item i {
  margin-right: 10px;
  width: 20px;
  text-align: center;
}

@media (max-width: 868px) {
  .sidebar {
    width: 200px;
  }
}