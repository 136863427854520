.transaction-table {
    font-family: Arial, sans-serif;
    margin: 20px;
    margin-top: 9%;
  }
  
  .filters {
    margin-bottom: 20px;
  }
  
  .filters input {
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }

  .filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filters input,
  .filters select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }