.header-text {
    background-color: #ededff;
    color: #000000;
    padding: 20px;
    margin: 120px auto 20px;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.video-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
}

.form-container {
    width: 100%;
    max-width: 500px;
}

.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

.dropdown-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.dropdown-container select {
    width: 48%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.select-dropdown {
    margin-bottom: 20px;
}

.submit-button {
    text-align: center;
}

.submit-button button {
    background-color: #c0025e;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button button:hover {
    background-color: #ff007b;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .header-text {
        margin-top: 22%;
    }
}