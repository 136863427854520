.user-rankings-container {
    max-width: 800px;
    margin: auto;
    margin-top: 7%;
    padding: 20px;
  }
  
  .rankings-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .rankings-table th,
  .rankings-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .rankings-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .rank-tag {
    display: inline-block;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .rank-tag.gold {
    background-color: #ffd700;
    color: #000;
  }
  
  .rank-tag.silver {
    background-color: #c0c0c0;
    color: #000;
  }
  
  .rank-tag.bronze {
    background-color: #cd7f32;
    color: #fff;
  }
  
  .top-1 {
    background-color: rgba(255, 215, 0, 0.1);
  }
  
  .top-2 {
    background-color: rgba(192, 192, 192, 0.1);
  }
  
  .top-3 {
    background-color: rgba(205, 127, 50, 0.1);
  }
  
  .error-message,
  .loading-message {
    text-align: center;
    margin-top: 20px;
    font-style: italic;
  }